import React from 'react';
import { useTranslation } from 'react-i18next';

import { Metadata } from '../components/core/metadata';
import { create } from '../helpers/bem';

import './about.scss';

const bem = create('page-about');

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <Metadata
      title={t('About & Contact')}
      description="More information about Frontend Developer Yves Rijckaert and means to contact."
    >
      <article className={bem()}>
        <div className={bem('content')}>
          <h2 className={bem('headline')}>{t('Yves Rijckaert')}</h2>
          <p className={bem('subline')}>
            {t('Yves Rijckaert is a frontend developer who specializes in web standards, accessibility, inclusivity and creative coding.')}
          </p>
        </div>
        <hr className={bem('divider')} />
        <div className={bem('info')}>
          {/* https://adrianroselli.com/2017/11/a-responsive-accessible-table.html */}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <div role="region" aria-labelledby="caption" tabIndex="0" className={bem('table-wrapper')}>
            <table>
              <caption id="caption">
                <h3 className={bem('title')}>{t('Experience')}</h3>
              </caption>
              <thead>
                <tr>
                  <th scope="col">{t('Company')}</th>
                  <th scope="col">{t('Role')}</th>
                  <th scope="col">{t('Date')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      className={bem('table-link')}
                      href="https://www.contentful.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t('Contentful')}
                    </a>
                  </td>
                  <td>{t('Software Engineer')}</td>
                  <td>
                    <time dateTime="2021">{t('2021')}</time>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      className={bem('table-link')}
                      href="https://www.edenspiekermann.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t('Edenspiekermann')}
                    </a>
                  </td>
                  <td>{t('Front-end developer')}</td>
                  <td>
                    <time dateTime="2019">{t('2019')}</time>
                    &ndash;
                    <time dateTime="2021">{t('2021')}</time>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <caption>
                <h3 className={bem('title')}>{t('Education')}</h3>
              </caption>
              <thead>
                <tr>
                  <th scope="col">{t('Name')}</th>
                  <th scope="col">{t('Date')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      className={bem('table-link')}
                      href="https://www.devine.be/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t('Digital design and development (Devine)')}
                    </a>
                  </td>
                  <td>
                    <time dateTime="2016">{t('2016')}</time>
                    &ndash;
                    <time dateTime="2019">{t('2019')}</time>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <caption>
                <h3 className={bem('title')}>{t('Awards and recognitions')}</h3>
              </caption>
              <thead>
                <tr>
                  <th scope="col">{t('Name')}</th>
                  <th scope="col">{t('Date')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      className={bem('table-link')}
                      href="https://kortrijkcreativityweek.be/exposities-en-installaties/expositie-we-are-the-next-generation?p=1"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t('Selection ‘We are the next generation’')}
                    </a>
                  </td>
                  <td>
                    <time dateTime="2019">{t('2019')}</time>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      className={bem('table-link')}
                      href="https://www.youtube.com/watch?v=1ZuJOjj8ttE"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t('Feature in the Devine Showreel')}
                    </a>
                  </td>
                  <td>
                    <time dateTime="2019">{t('2019')}</time>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <footer>
            <h3 className={bem('title')}>{t('Contact & Socials')}</h3>
            <ul className={bem('list')}>
              <li className={bem('list-item')}>
                <a
                  className={bem('link')}
                  href="mailto:hi@yvesrijckaert.com"
                >
                  {t('Email')}
                </a>
              </li>
              <li className={bem('list-item')}>
                <a
                  className={bem('link')}
                  href="https://www.linkedin.com/in/yves-rijckaert-302b1244/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('LinkedIn')}
                </a>
              </li>
              <li className={bem('list-item')}>
                <a
                  className={bem('link')}
                  href="https://github.com/YvesRijckaert"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('Github')}
                </a>
              </li>
              <li className={bem('list-item')}>
                <a
                  className={bem('link')}
                  href="https://www.instagram.com/yvesrijckaert"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('Instagram')}
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </article>
    </Metadata>
  );
};

export default AboutPage;
